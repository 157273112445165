<template>
  <div class="bank-book-history" :class="{skeleton: !state.loaded}">
    <div class="wrapper">
      <div class="title font-md">
        <span>최근 거래 계좌</span>
      </div>
      <div class="desc font-sm">최근에 거래하신 증권 계좌 목록입니다.</div>
      <div class="content">
        <ul class="tight font-sm" v-if="state.details.length">
          <li v-for="(d, idx) in state.details" :key="idx" @click="select(d)">
            <span>{{ d.secucoAcopnoText }} </span>
            <span>{{ d.secucoAcopno && d.secucoAcopno.toString() === "20" ? "" : d.invstrTrsacNo }}</span>
          </li>
        </ul>
        <div v-else-if="state.loaded">
          <NoData/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import Date from "@/components/Date";
import NoData from "@/components/NoData";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "modalRecentAccounts";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {NoData, Date},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
      load();
    });

    const state = reactive({
      loaded: false,
      details: [],
      args: {
        pageIndex: 1,
        searchKeywordFrom: "2022-03-25",
        searchKeywordTo: "2022-03-31",
      }
    });

    const modalParams = store.getters.modalParams(component);

    const select = (d) => {
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {
            modal,
            params: {
              secucoAcopno: d.secucoAcopno?.toString(),
              invstTrsacNo: d.invstrTrsacNo
            }
          });
        }
      });
    };

    const load = () => {
      state.details = [];
      for (let i = 0; i < 5; i += 1) {
        state.details.push({
          secucoAcopno: undefined,
          invstrTrsacNo: "000000000000",
          secucoAcopnoText: "Wait a moment",
        });
      }

      state.loaded = false;
      http.get(`/api/invest/project/${modalParams.investSeq}/subscribe/recent-accounts`,).then(({data}) => {
        state.loaded = true;
        state.details = [];
        data.body.forEach(d => {
          d.invstrTrsacNo && state.details.push({
            secucoAcopno: d.secucoAcopno,
            invstrTrsacNo: d.invstrTrsacNo,
            secucoAcopnoText: definitions.codes.obj.securitiesCompany[d.secucoAcopno],
          });
        });
      }).catch(httpError(() => {
        store.commit("closeModal", {name: component.name});
      }));
    };

    return {component, state, load, select};
  }
});
</script>

<style lang="scss" scoped>
.bank-book-history {
  background: #fff;
  padding: $px25;

  > .wrapper {
    position: relative;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #5c5c5c;
    }

    .content {
      ul {
        li {
          border: $px1 solid #eee;
          padding: $px18;
          cursor: pointer;
          background: #f8f9fa;
          border-radius: $px4;

          &:hover {
            background: #e2e6ea;
            border-color: #e2e6ea;
          }

          &:not(:last-child) {
            margin-bottom: $px15;
          }
        }
      }
    }
  }

  &.skeleton {
    > .wrapper {
      .content ul li > span {
        @include skeleton;
      }
    }
  }
}
</style>